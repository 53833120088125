<template>
  <v-container
    v-bind="propsPageMainContainer"
    data-cy="documents-access-show-page"
  >
    <v-row v-bind="propsPageMainRow">
      <v-col v-bind="propsPageMainCol">
        <DocumentsAccessShow :documentId="documentId"></DocumentsAccessShow>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DocumentsAccessShow from "../components/DocumentsAccessShow.vue";

export default {
  name: "DocumentsAccessShowPage",
  components: {
    DocumentsAccessShow,
  },
  data: () => ({}),
  created() {},
  computed: {
    // 1) Computed property to set the document id.
    documentId() {
      let documentId = "";
      if (this.$route.params.id) {
        documentId = this.$route.params.id.toString();
      }
      return documentId;
    },
  },
};
</script>
