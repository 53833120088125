<template>
  <v-card
    v-bind="propsCompSheet"
    :loading="status.loading"
    class="d-flex flex-column"
    style="height: 100%"
  >
    <v-card-title>
      {{ $t("documentsAccess.title") }}
    </v-card-title>

    <v-list two-line>
      <v-list-item v-for="item in documentsAccessFilter" :key="item.id">
        <v-list-item-content>
          <v-list-item-title
            >{{ $t(`documentsAccess.status.${item.action}`) }}
            <v-chip
              v-if="item.documentShareId"
              small
              color="amber"
              class="ml-2"
              >{{ $t("documentsAccess.fromShare") }}</v-chip
            >
          </v-list-item-title>
          <v-list-item-subtitle
            >{{ getUserName(item.userId) }} &mdash;
            {{ formatTime(item.time) }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-alert v-model="status.showReadError" dismissible type="error">
      {{ $t("documents.error") }}
    </v-alert>
  </v-card>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";

export default {
  name: "DocumentsAccessShow",
  mixins: [ComponentStatus],
  props: {
    documentId: {
      type: String,
      default: "",
    },
  },
  computed: {
    documentsAccess() {
      return this.$store.getters["documentsAccess/readByDocumentId"](
        this.documentId
      );
    },
    documentsAccessFilter() {
      return this.documentsAccess.filter((item) => {
        if (item.action === "read") {
          return false;
        }
        return true;
      });
    },
  },
  created() {
    this.setStatusReading();
    this.$store
      .dispatch("documentsAccess/readAll", this.documentId)
      .then((/* result */) => {
        this.setStatusReadSuccess();
      })
      .catch((/* error */) => {
        this.setStatusReadError();
      });
  },
  methods: {
    getUserName(userId) {
      let userName = this.$t("users.unknown");
      let user = this.$store.getters["users/readById"](userId);
      if (user.id) {
        if (user.name) {
          userName = user.name;
        }
        if (user.surnames) {
          if (user.name) {
            userName += " ";
          }
          userName += user.surnames;
        }
      }
      return userName;
    },
    formatTime(time) {
      let tempTime = new Date(time);
      return this.$d(tempTime, "long");
    },
  },
};
</script>
